'use client'

import {useEffect} from 'react'
import * as Sentry from '@sentry/nextjs'
import ConnectionError from '~components/errors/connection-error'
import AccessDenied from '~components/errors/access-denied'

export default function Error({
  error,
}: {
  error: Error & {digest?: string; status?: number}
}) {
  useEffect(() => {
    // Log the error to Sentry
    Sentry.captureException(error)
  }, [error])

  const renderErrorComponent = () => {
    switch (error.status) {
      case 400:
        return <ConnectionError />
      case 403:
        return <AccessDenied />
      default:
        return <ConnectionError />
    }
  }

  return (
    <html>
      <body>{renderErrorComponent()}</body>
    </html>
  )
}
