'use client'
import Button from '@fool/jester-ui/Button'
import Heading from '@fool/jester-ui/Heading'
import {Link} from '~components/common/link'
import {ConnectionErrorIcon} from '~components/jester-temp/icons.tsx'

export default function ConnectionError() {
  return (
    <div>
      <ConnectionErrorIcon />
      <Heading as="h1" looksLike="h1">
        Uh oh! Connection problem.
      </Heading>
      <p className="text-body-regular">
        Oops, sorry Fool, something went wrong. We&apos;re working to fix the
        issue.
      </p>
      <p className="text-body-regular pb-3">
        In the meantime, head back to your dashboard to see our recent stock
        recommendations.
      </p>
      <Link href="/">
        <Button variant="primary">Go to Dashboard</Button>
      </Link>
    </div>
  )
}
