'use client'
import Button from '@fool/jester-ui/Button'
import Heading from '@fool/jester-ui/Heading'
import {Link} from '~components/common/link'
import {AccessDeniedIcon} from '~components/jester-temp/icons.tsx'

export default function AccessDenied() {
  return (
    <div>
      <AccessDeniedIcon />
      <Heading as="h1" looksLike="h1">
        Sorry, you do not have access to this content.
      </Heading>
      <p className="text-body-regular">
        If you would like to learn how to gain access to this content, please
        contact our customer service team.
      </p>
      <Link href="/">
        <Button variant="primary">Go to Dashboard</Button>
      </Link>
    </div>
  )
}
